import { useState, useEffect } from "react";
import * as d3 from "d3";

import { Auth } from "aws-amplify";

//Apollo queries
import { useLazyQuery } from "@apollo/client";
import {
  current_selection,
  current_user,
  usuario_config,
  login_state,
} from "../graphql/localstate/localState";

import getUsuarioConfig from "../graphql/queries/getUsuarioConfig";

import {
  selectedLanguage,
  selectedMetric,
} from "../graphql/localstate/localState";

import { useReactiveVar } from "@apollo/client";
import { UpdateCurrentSelection } from "./LocalStorageFunctions";
import { NombreTemporada } from "./GlobalFunctions";

function useInitCurrentSelection() {
  // const curr_user  = useReactiveVar(current_user)
  // const selectedLanguageVar = useReactiveVar(selectedLanguage)
  // const selectedMetricVar = useReactiveVar(selectedMetric)
  const curr_selection = useReactiveVar(current_selection);
  const usr_config = useReactiveVar(usuario_config);

  const l_state = useReactiveVar(login_state);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading_init, set_loading_init] = useState(false);
  const [username, setUsername] = useState("");

  const [
    qgetUsuarioConfig,
    {
      loading: loading_usuario,
      error: error_usuario,
      data: data_usuario,
      networkStatus,
      refetch,
    },
  ] = useLazyQuery(getUsuarioConfig, {
    fetchPolicy: "cache-and-network",
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  //  useEffect(() => {
  //   try {
  //     setError(null);
  //     set_loading_init(true);

  //     Auth.currentAuthenticatedUser({
  //       bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  //     }).then(user => {
  //       setUsername(user.username);

  //     }).catch(err => setError(err));
  //   }
  //   catch (e) {
  //     setError(e);
  //     console.log(e)
  //   }
  //   finally {
  //    // set_loading_init(false);
  //   }
  // }, [l_state]);

  useEffect(() => {
    // set_loading_init(true)
    if (l_state?.state === "signedin" && l_state?.auth_data !== undefined) {
      //console.log("signed in query", username)
      qgetUsuarioConfig({
        variables: { username: l_state?.auth_data.username },
      });
    }
  }, [l_state]);

  useEffect(() => {
    if (data_usuario) {
      let aux = Object.assign({}, data_usuario.getUsuarioConfig);

      if (
        aux.usuario.rol_id == 0 ||
        aux.usuario.rol_id == 1 ||
        aux.usuario.rol_id == 2 ||
        aux.usuario.rol_id == 3
      ) {
        aux.autorizar_empresa_productor = true;
      } else {
        aux.autorizar_empresa_productor = true;
        const auxmap = d3.index(
          data_usuario.getUsuarioConfig.permisos_predio,
          (d) => d.predio_id
        );
        aux.permisos_predio_map = auxmap;
      }

      usuario_config(aux);
    }
  }, [loading_usuario]);

  useEffect(() => {
    const local_storage_state = JSON.parse(
      window.localStorage.getItem("currentStorageSelection")
    );
    if (
      data_usuario &&
      curr_selection === undefined &&
      local_storage_state === null
    ) {
      // login
      //console.log("data_usuario && curr_selection === undefined && local_storage_state === null", data_usuario);
      let aux = {
        cliente_id:
          data_usuario.getUsuarioConfig.cliente_config.select_cliente_id,
        cliente_nombre:
          data_usuario.getUsuarioConfig.cliente_config.select_cliente_nombre,
        productor_id:
          data_usuario.getUsuarioConfig.cliente_config.select_productor_id,
        productor_nombre:
          data_usuario.getUsuarioConfig.cliente_config.select_productor_nombre,
        predio_id:
          data_usuario.getUsuarioConfig.cliente_config.select_predio_id,
        predio_nombre:
          data_usuario.getUsuarioConfig.cliente_config.select_predio_nombre,
        cuartel_id:
          data_usuario.getUsuarioConfig.cliente_config.select_cuartel_id,
        cuartel_nombre:
          data_usuario.getUsuarioConfig.cliente_config.select_cuartel_nombre,
        temporada_id:
          data_usuario.getUsuarioConfig.cliente_config.select_temporada_id,
        temporada_nombre: NombreTemporada(
          data_usuario.getUsuarioConfig.cliente_config.select_temporada_id
        ),
        lenguaje: data_usuario.getUsuarioConfig.cliente_config.lenguaje,
        sistema_metricas:
          data_usuario.getUsuarioConfig.cliente_config.sistema_metricas,
      };

      current_selection(aux);
      UpdateCurrentSelection(aux);
      // console.log("lenguaje from graphql:", data_usuario.getUsuarioConfig.cliente_config.lenguaje)
      selectedLanguage(data_usuario.getUsuarioConfig.cliente_config.lenguaje);
      selectedMetric(
        data_usuario.getUsuarioConfig.cliente_config.sistema_metricas
      );
    }

    if (local_storage_state !== null && curr_selection === undefined) {
      // qgetClienteConfig({ variables: { cliente_id: current_storage_selection?.cliente_id } });
      // console.log("local_storage_state !== null && curr_selection === undefined ");

      UpdateCurrentSelection(local_storage_state);
      // console.log(local_storage_state)
      selectedLanguage(local_storage_state.lenguaje);
      selectedMetric(local_storage_state.sistema_metricas);
    }

    set_loading_init(false);
  }, [loading_usuario]);

  return loading_init;
}

export default useInitCurrentSelection;
